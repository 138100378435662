import React from 'react';
import URLUpload from '../components/URLUploadPage/URLUpload';
import AnimationWrapper from '../components/common/AnimationWrapper';

const URLUploadContainer: React.FC = () => (
    <AnimationWrapper>
        <URLUpload />
    </AnimationWrapper>
);

export default URLUploadContainer;
