import axios from 'axios';

interface PromptParameters {
    friendliness: number;
    chattiness: number;
    technicality: number;
}

interface ChatParametersResponse {
    promptParameters: PromptParameters;
    imageURL: string;
    scrapedDataUrl: string;
}

interface SendMessageResponse {
    response: string;
    conversationId: string;
}

class ChatService {
    private readonly apiUrl: string;
    private readonly apiKey: string;
    private readonly projectId: string;

    constructor() {
        this.apiUrl = 'https://api.superdashhq.com/api/v1/chatbot/customizableChat';
        this.apiKey = 'f09d83b5-02ca-455b-8770-adcfd263802a';
        this.projectId = '6679165f96cb7a6fb0c67182';
    }

    async getChatParameters(id: number): Promise<ChatParametersResponse> {
        try {
            const url = `https://app3-g4lztzykma-uc.a.run.app/chat/parameters/${id}`;
            // const url = `http://localhost:3001/chat/parameters/${id}`;
            const response = await axios.get<ChatParametersResponse>(url);
            return response.data;
        } catch (error) {
            console.error('Error fetching chat parameters:', error);
            throw error;
        }
    }

    async sendMessage(conversationId: string, question: string, id: number): Promise<SendMessageResponse> {
        try {
            const { promptParameters } = await this.getChatParameters(id);

            const response = await axios.post<SendMessageResponse>(this.apiUrl, {
                projectId: this.projectId,
                promptParameters,
                conversationId,
                question
            }, {
                headers: {
                    'x-api-key': this.apiKey
                }
            });
            return response.data;
        } catch (error) {
            console.error('Error sending message:', error);
            throw error;
        }
    }

    async addURLToConversation(conversationId: string, url: string): Promise<void> {
        try {
            const apiUrl = `https://api.superdashhq.com/api/v1/knowledgebase/addURLToConversation/${this.projectId}/${conversationId}`;
            const response = await axios.post(apiUrl, { url }, {
                headers: {
                    'x-api-key': this.apiKey,
                    'Content-Type': 'application/json'
                }
            });
            console.log('URL added to conversation:', response.status, response.statusText);
        } catch (error: any) {
            console.error('Error adding URL to conversation:', error.response ? error.response.statusText : error.message);
            throw error;
        }
    }
}

export default new ChatService();
