import React from 'react';
import { Box, IconButton } from '@mui/material';
import { useSwipeable } from 'react-swipeable';
import KeyboardDoubleArrowLeftIcon from '@mui/icons-material/KeyboardDoubleArrowLeft';
import KeyboardDoubleArrowRightIcon from '@mui/icons-material/KeyboardDoubleArrowRight';

// @ts-ignore
import image1 from '../images/1.png';
// @ts-ignore
import image2 from '../images/2_1.png';
// @ts-ignore
import image3 from '../images/3_1.png';
// @ts-ignore
import image4 from '../images/4.png';
// @ts-ignore
import image5 from '../images/5.png';

interface SliderProps {
    activeImage: number;
    setActiveImage: React.Dispatch<React.SetStateAction<number>>;
}

const images = [image1, image2, image3, image4, image5];

const SliderComponent: React.FC<SliderProps> = ({ activeImage, setActiveImage }) => {
    const handlers = useSwipeable({
        onSwipedLeft: () => setActiveImage((prev) => (prev < images.length - 1 ? prev + 1 : prev)),
        onSwipedRight: () => setActiveImage((prev) => (prev > 0 ? prev - 1 : prev)),
        trackMouse: true
    });

    return (
        <Box
            sx={{
                position: 'relative',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                width: "80%"
            }}
        >
            {activeImage > 0 && (
                <IconButton
                    onClick={() => setActiveImage((prev) => (prev > 0 ? prev - 1 : prev))}
                    sx={{ position: 'absolute', left: '-40px', zIndex: 2, color: 'white' }}
                >
                    <KeyboardDoubleArrowLeftIcon sx={{ color: 'black' }} />
                </IconButton>
            )}
            <Box
                {...handlers}
                sx={{
                    width: '100%',
                    maxWidth: '300px',
                    overflow: 'hidden',
                    position: 'relative',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    backgroundColor: 'black', // Added background color for better visibility
                    borderRadius: '8px'
                }}
            >
                <Box
                    sx={{
                        display: 'flex',
                        transition: 'transform 0.3s ease',
                        transform: `translateX(-${activeImage * 100}%)`,
                        width: `${images.length * 100}%`
                    }}
                >
                    {images.map((image, index) => (
                        <Box
                            key={index}
                            sx={{
                                minWidth: '100%',
                                maxWidth: '100%',
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                opacity: index === activeImage ? 1 : 0.5,
                                transform: (index === activeImage - 1 || index === activeImage + 1) ? 'scale(0.9)' : 'scale(1)',
                                zIndex: index === activeImage ? 1 : 0,
                            }}
                        >
                            <Box
                                component="img"
                                src={image}
                                alt={`slider-${index}`}
                                sx={{
                                    width: '100%',
                                    maxWidth: '300px',
                                    borderRadius: '8px',
                                }}
                            />
                        </Box>
                    ))}
                </Box>
            </Box>
            {activeImage < images.length - 1 && (
                <IconButton
                    onClick={() => setActiveImage((prev) => (prev < images.length - 1 ? prev + 1 : prev))}
                    sx={{ position: 'absolute', right: '-40px', zIndex: 2, color: 'white' }}
                >
                    <KeyboardDoubleArrowRightIcon sx={{ color: 'black' }} />
                </IconButton>
            )}
        </Box>
    );
};

export default SliderComponent;
