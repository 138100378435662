import React from 'react';
import FinalCustomImage from '../components/FinalCustomImagePage/FinalCustomImage';
import AnimationWrapper from '../components/common/AnimationWrapper';

const FinalCustomImageContainer: React.FC = () => (
    <AnimationWrapper>
        <FinalCustomImage />
    </AnimationWrapper>
);

export default FinalCustomImageContainer;
