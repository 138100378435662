import React, { useState, useEffect, useRef } from 'react';
import { TextField, Box, Avatar, Paper, IconButton, CircularProgress, Typography } from '@mui/material';
import SendIcon from '@mui/icons-material/Send';
import ChatService from '../services/ChatService';

interface Message {
    from: 'user' | 'bot';
    text: string;
    isLoading?: boolean;
}

const Chat: React.FC = () => {
    const [conversationId, setConversationId] = useState<string>(() => localStorage.getItem('conversationId') || '');
    const [question, setQuestion] = useState<string>('');
    const [messages, setMessages] = useState<Message[]>([]);
    const [imageURL, setImageURL] = useState<string>('');
    const [scrapedDataUrl, setScrapedDataUrl] = useState<string>('');
    const [id] = useState<number>(() => parseInt(localStorage.getItem('junoId') || '0', 10));
    const [urlAdded, setUrlAdded] = useState<boolean>(() => localStorage.getItem('urlAdded') === 'true');
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [isKeyboardOpen, setIsKeyboardOpen] = useState<boolean>(false);
    const messagesEndRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        const fetchChatParameters = async () => {
            try {
                const { imageURL, scrapedDataUrl } = await ChatService.getChatParameters(id);
                setImageURL(imageURL);
                setScrapedDataUrl(scrapedDataUrl);
            } catch (error) {
                console.error('Error fetching chat parameters:', error);
            }
        };

        fetchChatParameters();

        const handleKeyboardShow = () => setIsKeyboardOpen(true);
        const handleKeyboardHide = () => setIsKeyboardOpen(false);

        window.addEventListener('keyboardDidShow', handleKeyboardShow);
        window.addEventListener('keyboardDidHide', handleKeyboardHide);

        return () => {
            window.removeEventListener('keyboardDidShow', handleKeyboardShow);
            window.removeEventListener('keyboardDidHide', handleKeyboardHide);
        };
    }, [id]);

    useEffect(() => {
        if (messagesEndRef.current) {
            messagesEndRef.current.scrollIntoView({ behavior: 'smooth' });
        }
    }, [messages]);

    const handleSendMessage = async () => {
        if (question.trim()) {
            try {
                setIsLoading(true);
                setMessages((prevMessages) => [
                    ...prevMessages,
                    { from: 'user', text: question },
                    { from: 'bot', text: '', isLoading: true }
                ]);
                setQuestion(''); // Clear the input field
                const response = await ChatService.sendMessage(conversationId, question, id);
                setMessages((prevMessages) => {
                    const newMessages = [...prevMessages];
                    newMessages.pop(); // Remove loading message
                    newMessages.push({ from: 'bot', text: response.response });
                    return newMessages;
                });
                setIsLoading(false);
                if (!conversationId && response.conversationId) {
                    setConversationId(response.conversationId);
                    localStorage.setItem('conversationId', response.conversationId);

                    if (!urlAdded) {
                        await ChatService.addURLToConversation(response.conversationId, scrapedDataUrl);
                        setUrlAdded(true);
                        localStorage.setItem('urlAdded', 'true');
                    }
                }
            } catch (error) {
                console.error('Error sending message:', error);
                setIsLoading(false);
            }
        }
    };

    return (
        <Box sx={{ width: '90%', margin: '0 auto', padding: 2, display: 'flex', flexDirection: 'column', height: '85vh', overflow: 'hidden', alignItems: 'center', fontFamily: "'Inter', sans-serif" }}>
            <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                <Typography variant="h4" sx={{ fontFamily: "'Inter', sans-serif" }}>Chat with Juno</Typography>
                <Avatar src={imageURL} alt="Chat Avatar" sx={{ width: 56, height: 56, marginBottom: 1 }} />
            </Box>
            <Paper sx={{ flexGrow: 1, marginTop: 2, overflowY: 'auto', padding: 2, border: 'none', boxShadow: 'none', width: '100%', transform: isKeyboardOpen ? 'scale(0.9)' : 'scale(1)', transition: 'transform 0.3s ease' }}>
                {messages.map((message, index) => (
                    <Box key={index} sx={{ textAlign: message.from === 'user' ? 'right' : 'left', margin: '10px 0' }}>
                        <Paper sx={{ display: 'inline-block', padding: 1, backgroundColor: message.from === 'user' ? '#e1f5fe' : '#f1f8e9', wordBreak: 'break-word', border: 'none', boxShadow: 'none' }}>
                            {message.isLoading ? (
                                <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '20px' }}>
                                    <CircularProgress size={20} />
                                </Box>
                            ) : (
                                <p>{message.text}</p>
                            )}
                        </Paper>
                    </Box>
                ))}
                <div ref={messagesEndRef} />
            </Paper>
            <Box sx={{ display: 'flex', marginTop: 2, alignItems: 'center', width: '100%', border: 'none', boxShadow: 'none' }}>
                <TextField
                    variant="outlined"
                    fullWidth
                    value={question}
                    onChange={(e) => setQuestion(e.target.value)}
                    placeholder="Write a message..."
                    sx={{
                        flexGrow: 1,
                        marginRight: 1,
                        '& .MuiOutlinedInput-root': {
                            borderRadius: '25px',
                            '& fieldset': {
                                border: 'none'
                            },
                            boxShadow: 'none'
                        }
                    }}
                />
                <IconButton color="primary" onClick={handleSendMessage}>
                    <SendIcon />
                </IconButton>
            </Box>
        </Box>
    );
};

export default Chat;
