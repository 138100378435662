import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Box, TextField, Button, Typography, Divider } from '@mui/material';
import GoogleIcon from '@mui/icons-material/Google';
import UserService from '../services/UserService';

const Auth: React.FC = () => {
    const [formValues, setFormValues] = useState({
        email: '',
        password: '',
        phoneNumber: '',
        firstName: '',
        lastName: ''
    });

    const [formErrors, setFormErrors] = useState({
        email: '',
        password: '',
        phoneNumber: '',
        firstName: '',
        lastName: ''
    });

    const navigate = useNavigate();

    const validate = () => {
        let errors: any = {};
        if (!formValues.email) {
            errors.email = 'Email is required';
        } else if (!/\S+@\S+\.\S+/.test(formValues.email)) {
            errors.email = 'Invalid email address';
        }

        if (!formValues.password) {
            errors.password = 'Password is required';
        } else if (formValues.password.length < 6) {
            errors.password = 'Password must be at least 6 characters';
        }

        if (formValues.phoneNumber && !/^\+?[0-9]+$/.test(formValues.phoneNumber)) {
            errors.phoneNumber = 'Wrong number';
        }

        if (!formValues.firstName) {
            errors.firstName = 'First name is required';
        }

        setFormErrors(errors);
        return Object.keys(errors).length === 0;
    };

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = event.target;
        setFormValues({
            ...formValues,
            [name]: value
        });
    };


    const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        if (!validate()) return;

        const _id = parseInt(localStorage.getItem('junoId') || '0', 10);
        try {
            const response = await UserService.createUser({
                _id,
                ...formValues,
                isGoogleUser: false
            });
            console.log(response);
            navigate('/chat');
        } catch (error: any) {
            if (error.response && error.response.status === 400) {
                navigate('/chat');
            } else {
                console.error('Error creating user:', error);
            }
        }
    };

    const handleGoogleAuth = () => {
        const _id = parseInt(localStorage.getItem('junoId') || '0', 10);
        if (_id) {
            const redirectUri = `https://app3-g4lztzykma-uc.a.run.app/auth/google?id=${_id}`;
            // const redirectUri = `http://localhost:3001/auth/google?id=${_id}`;
            window.location.href = redirectUri;
        } else {
            console.error('User ID not found in localStorage');
        }
    };

    return (
        <Box display="flex" flexDirection="column" alignItems="center" justifyContent="center" minHeight="100vh" bgcolor="#ffffff">
            <Box display="flex" flexDirection="column" alignItems="center" sx={{ width: '100%' }}>
                <Typography variant="h3" gutterBottom sx={{ fontSize: '32px', fontFamily: "'Inter', sans-serif" }}>
                    Try your Juno
                </Typography>
                <Typography variant="body2" align="center" gutterBottom>
                    Create new account
                </Typography>
                <form onSubmit={handleSubmit}>
                    <TextField
                        fullWidth
                        id="email"
                        name="email"
                        label="Email*"
                        margin="normal"
                        value={formValues.email}
                        onChange={handleChange}
                        error={!!formErrors.email}
                        helperText={formErrors.email}
                    />
                    <TextField
                        fullWidth
                        id="password"
                        name="password"
                        label="Password*"
                        type="password"
                        margin="normal"
                        value={formValues.password}
                        onChange={handleChange}
                        error={!!formErrors.password}
                        helperText={formErrors.password}
                    />
                    <TextField
                        fullWidth
                        id="phoneNumber"
                        name="phoneNumber"
                        label="Phone Number"
                        margin="normal"
                        value={formValues.phoneNumber}
                        onChange={handleChange}
                        error={!!formErrors.phoneNumber}
                        helperText={formErrors.phoneNumber}
                    />
                    <TextField
                        fullWidth
                        id="firstName"
                        name="firstName"
                        label="Name*"
                        margin="normal"
                        value={formValues.firstName}
                        onChange={handleChange}
                        error={!!formErrors.firstName}
                        helperText={formErrors.firstName}
                    />
                    <TextField
                        fullWidth
                        id="lastName"
                        name="lastName"
                        label="Last Name"
                        margin="normal"
                        value={formValues.lastName}
                        onChange={handleChange}
                        error={!!formErrors.lastName}
                        helperText={formErrors.lastName}
                    />
                    <Button type="submit" sx={{
                        width: '100%', height: '60px', backgroundColor: '#000000', fontSize: '16px', borderRadius: '8px', color: '#ffffff', textTransform: 'none', mt: 1,
                        '&:hover': { backgroundColor: '#3f51b5', },
                        '&:active': { backgroundColor: '#303f9f', },
                    }}>
                        Sign up with email
                    </Button>
                </form>
                <Divider sx={{ mb: 1, mt: 1 }}>
                    <Typography variant="body2" color="textSecondary">or continue with</Typography>
                </Divider>
                <Button startIcon={<GoogleIcon />} onClick={handleGoogleAuth} sx={{
                    width: '100%', height: '60px', backgroundColor: '#000000', fontSize: '16px', borderRadius: '8px', color: '#ffffff', textTransform: 'none', mb: 1,
                    '&:hover': { backgroundColor: '#3f51b5', },
                    '&:active': { backgroundColor: '#303f9f', },
                }}>
                    Google
                </Button>
                <Typography variant="body2" align="center" color="textSecondary" mt={2}>
                    By clicking continue, you agree to our <a href="https://www.termsfeed.com/live/868ca480-60e5-4091-a7dd-3856efc2c37d" style={{ textDecoration: 'none' }}>Terms of Service</a> and <a href="https://www.termsfeed.com/live/4f3ebefe-fb02-4b06-818c-66a22151d721" style={{ textDecoration: 'none' }}>Privacy Policy</a>
                </Typography>
            </Box>
        </Box>
    );
};

export default Auth;
