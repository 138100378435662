import React from 'react';
import { Slider, Box, Typography, styled } from '@mui/material';

const CustomStyledSlider = styled(Slider)(({ theme }) => ({
    '& .MuiSlider-thumb': {
        color: '#7F7DDA', // Set the thumb color to blue
    },
}));

interface SlidersControlsProps {
    values: number[];
}

const SlidersControls: React.FC<SlidersControlsProps> = ({ values }) => (
    <Box
        display="flex"
        flexDirection="column"
        alignItems="center"
        width="100%"
        maxWidth="600px"
        sx={{ fontFamily: "'Inter', sans-serif", mt: 2 }}
    >
        {values.length === 0 ? (
            <Typography sx={{ fontFamily: "'Inter', sans-serif", fontSize: '16px', textAlign: 'center' }}>
                You can create your own Juno, just select the desired options.
            </Typography>
        ) : (
            <>
                <Box width="100%" display="flex" justifyContent="center" alignItems="center" mb={1}>
                    <Box width="80px" textAlign="left">
                        <Typography sx={{ fontSize: '14px' }}>Professional</Typography>
                    </Box>
                    <CustomStyledSlider value={values[0]} step={1} min={0} max={10} sx={{ flex: 1, mx: 2 }} disabled />
                    <Box width="80px" textAlign="right">
                        <Typography sx={{ fontSize: '14px' }}>Friendly</Typography>
                    </Box>
                </Box>
                <Box width="100%" display="flex" justifyContent="center" alignItems="center" mb={1}>
                    <Box width="80px" textAlign="left">
                        <Typography sx={{ fontSize: '14px' }}>Brief</Typography>
                    </Box>
                    <CustomStyledSlider value={values[1]} step={1} min={0} max={10} sx={{ flex: 1, mx: 2 }} disabled />
                    <Box width="80px" textAlign="right">
                        <Typography sx={{ fontSize: '14px' }}>Chatty</Typography>
                    </Box>
                </Box>
                <Box width="100%" display="flex" justifyContent="center" alignItems="center" mb={1}>
                    <Box width="80px" textAlign="left">
                        <Typography sx={{ fontSize: '14px' }}>Simple</Typography>
                    </Box>
                    <CustomStyledSlider value={values[2]} step={1} min={0} max={10} sx={{ flex: 1, mx: 2 }} disabled />
                    <Box width="80px" textAlign="right">
                        <Typography sx={{ fontSize: '14px' }}>Technical</Typography>
                    </Box>
                </Box>
                {/*<Box width="100%" display="flex" justifyContent="center" alignItems="center" mb={1}>*/}
                {/*    <Box width="80px" textAlign="left">*/}
                {/*        <Typography sx={{ fontSize: '14px' }}>Playful</Typography>*/}
                {/*    </Box>*/}
                {/*    <CustomStyledSlider value={values[3]} step={1} min={0} max={10} sx={{ flex: 1, mx: 2 }} disabled />*/}
                {/*    <Box width="80px" textAlign="right">*/}
                {/*        <Typography sx={{ fontSize: '14px' }}>Serious</Typography>*/}
                {/*    </Box>*/}
                {/*</Box>*/}
            </>
        )}
    </Box>
);

export default SlidersControls;
