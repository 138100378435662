import React, { useState, useEffect } from 'react';
import { Box, Button, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import JunoService from '../services/JunoService';

const FinalCustomImage: React.FC = () => {
    const navigate = useNavigate();
    const [imageUrl, setImageUrl] = useState<string>('');
    const [sliderValues, setSliderValues] = useState<number[]>([0, 0, 0]);
    const [message, setMessage] = useState<string>('');

    const messages = [
        "Juno loves assisting you around the clock.",
        "Juno is super friendly and always ready to help.",
        "Juno provides top-notch information anytime you need.",
        "Juno enjoys chatting and is always up for a conversation.",
        "Juno can switch between casual and formal depending on your mood.",
        "Juno mixes friendliness with professionalism perfectly.",
        "Juno ensures you have all the info you need.",
        "Juno isn't just a tool, but a friendly companion.",
        "Juno is committed to giving you the best assistance.",
        "Juno's responses are always spot-on and helpful.",
        "Juno can keep things formal when needed.",
        "Juno's laid-back style makes it easy to chat.",
        "Juno is great at providing business insights.",
        "Juno's friendly vibe makes interactions smooth.",
        "Juno tailors its style to match your needs."
    ];

    useEffect(() => {
        const fetchData = async () => {
            try {
                const id = parseInt(localStorage.getItem('junoId') || '0', 10);
                if (id) {
                    const juno = await JunoService.findOne(id);
                    setImageUrl(juno.imageUrl);
                    setSliderValues(juno.sliderValues);
                } else {
                    console.error('ID is not valid');
                }
            } catch (error) {
                console.error('Error fetching Juno data:', error);
            }
        };

        fetchData();
    }, []);

    useEffect(() => {
        const getMessage = () => {
            const [business, friendliness, formality] = sliderValues;

            if (business <= 3 && friendliness <= 3 && formality <= 3) {
                return messages[0];
            } else if (business <= 3 && friendliness >= 7) {
                return messages[1];
            } else if (business >= 7 && formality >= 7) {
                return messages[2];
            } else if (friendliness >= 7 && formality <= 3) {
                return messages[3];
            } else if (friendliness >= 4 && friendliness <= 6 && formality >= 4 && formality <= 6) {
                return messages[4];
            } else if (friendliness >= 4 && friendliness <= 6 && business >= 4 && business <= 6) {
                return messages[5];
            } else if (business <= 3 && formality >= 7) {
                return messages[6];
            } else if (friendliness >= 7) {
                return messages[7];
            } else if (business >= 7) {
                return messages[8];
            } else if (formality >= 7) {
                return messages[9];
            } else if (formality <= 3) {
                return messages[10];
            } else if (business <= 3 && friendliness >= 4 && friendliness <= 6) {
                return messages[11];
            } else if (business >= 7 && friendliness >= 4 && friendliness <= 6) {
                return messages[12];
            } else if (friendliness >= 4 && friendliness <= 6 && formality >= 7) {
                return messages[13];
            } else {
                return messages[14];
            }
        };

        setMessage(getMessage());
    }, [sliderValues]);

    const handleNextStep = async () => {
        try {
            const id = parseInt(localStorage.getItem('junoId') || '0', 10);
            if (id) {
                await JunoService.saveImageToS3(id);
                navigate('/upload-url');
            } else {
                console.error('ID is not valid');
            }
        } catch (error) {
            console.error('Error saving image to S3:', error);
        }
    };

    return (
        <Box
            display="flex"
            flexDirection="column"
            alignItems="center"
            textAlign="center"
            p={2}
            sx={{
                fontFamily: "'Inter', sans-serif",
                height: '100vh',
                justifyContent: 'space-between',
                overflow: 'hidden',
            }}
        >
            <Typography variant="h3" sx={{ fontSize: '32px', fontFamily: "'Inter', sans-serif" }}>
                Your Juno
            </Typography>
            <Box
                width="80%"
                maxWidth="400px"
                boxShadow={3}
                borderRadius={2}
                overflow="hidden"
                mb={2}
            >
                {imageUrl ? (
                    <img
                        src={imageUrl}
                        alt="Generated"
                        style={{ width: '100%', display: 'block' }}
                    />
                ) : (
                    <Typography variant="body1">Loading image...</Typography>
                )}
            </Box>
            <Typography
                variant="body1"
                align="center"
                px={2}
                mb={3}
            >
                {message}
            </Typography>
            <Box display="flex" flexDirection="column" alignItems="center" width="100%">
                <Button
                    sx={{
                        width: '100%',
                        height: '60px',
                        backgroundColor: '#000000',
                        fontSize: '16px',
                        borderRadius: '8px',
                        color: '#ffffff',
                        textTransform: 'none',
                        mb: 1,
                        '&:hover': {
                            backgroundColor: '#3f51b5',
                        },
                        '&:active': {
                            backgroundColor: '#303f9f',
                        },
                    }}
                    onClick={() => navigate('/create-custom-image')}
                >
                    Recreate
                </Button>
                <Button
                    sx={{
                        width: '100%',
                        height: '60px',
                        backgroundColor: '#000000',
                        fontSize: '16px',
                        borderRadius: '8px',
                        color: '#ffffff',
                        textTransform: 'none',
                        '&:hover': {
                            backgroundColor: '#3f51b5',
                        },
                        '&:active': {
                            backgroundColor: '#303f9f',
                        },
                    }}
                    onClick={handleNextStep}
                >
                    Next step &gt;&gt;
                </Button>
            </Box>
        </Box>
    );
};

export default FinalCustomImage;
