import React from 'react';
import CreateCustomImage from '../components/CreateCustomImagePage/CreateCustomImage';
import AnimationWrapper from '../components/common/AnimationWrapper';

const CreateCustomImageContainer: React.FC = () => (
    <AnimationWrapper>
        <CreateCustomImage />
    </AnimationWrapper>
);

export default CreateCustomImageContainer;
