import React from 'react';
import MainPage from '../components/MainPage/MainPage';
import AnimationWrapper from '../components/common/AnimationWrapper';

const MainPageContainer: React.FC = () => (
    <AnimationWrapper>
        <MainPage />
    </AnimationWrapper>
);

export default MainPageContainer;
