import React, { useState } from 'react';
import { TextField, Button, Typography, Box } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import JunoService from '../services/JunoService';

const URLUpload: React.FC = () => {
    const navigate = useNavigate();
    const [url, setUrl] = useState<string>('');

    const handleNextStep = async () => {
        try {
            const id = parseInt(localStorage.getItem('junoId') || '0', 10);
            if (!id) throw new Error('Invalid ID');

            let formattedUrl = url.trim().toLowerCase();
            if (!formattedUrl.match(/^https?:\/\//)) {
                formattedUrl = `https://${formattedUrl}`;
            }

            await JunoService.updateScrapedDataUrl(id, formattedUrl);
            navigate('/auth');
        } catch (error) {
            console.error('Failed to update scraped data URL', error);
        }
    };

    return (
        <Box
            display="flex"
            flexDirection="column"
            alignItems="center"
            sx={{
                fontFamily: "'Inter', sans-serif",
                height: '90vh',
                justifyContent: 'space-between',
                padding: '16px',
            }}
        >
            <Box
                display="flex"
                flexDirection="column"
                alignItems="center"
                sx={{ width: '100%' }}
            >
                <Typography variant="h3" sx={{ fontSize: '32px', fontFamily: "'Inter', sans-serif" }}>
                    Upload your URL
                </Typography>
                <TextField
                    label="URL"
                    variant="outlined"
                    sx={{ marginTop: '32px', width: '100%' }}
                    fullWidth
                    value={url}
                    onChange={(e) => setUrl(e.target.value)}
                />
                <Typography
                    variant="body1"
                    sx={{ fontSize: '14px', color: '#555555', marginTop: '16px', textAlign: 'center' }}
                >
                    We use the homepage data to quickly train Juno.
                    You will be able to get advice on the topics that are presented on the specified
                    Some URLs will not be able to be indexed by our systems, because they have a scrapping block installed on them.
                </Typography>
            </Box>
            <Box sx={{ width: '100%' }}>
                <Button
                    sx={{
                        width: '100%',
                        height: '60px',
                        backgroundColor: '#000000',
                        fontSize: '16px',
                        borderRadius: '8px',
                        color: '#ffffff',
                        textTransform: 'none',
                        mb: 1,
                        '&:hover': {
                            backgroundColor: '#3f51b5',
                        },
                        '&:active': {
                            backgroundColor: '#303f9f',
                        },
                    }}
                    onClick={() => navigate('/auth')}
                >
                    Skip
                </Button>
                <Button
                    sx={{
                        width: '100%',
                        height: '60px',
                        backgroundColor: '#000000',
                        fontSize: '16px',
                        borderRadius: '8px',
                        color: '#ffffff',
                        textTransform: 'none',
                        '&:hover': {
                            backgroundColor: '#3f51b5',
                        },
                        '&:active': {
                            backgroundColor: '#303f9f',
                        },
                    }}
                    onClick={handleNextStep}
                >
                    Next step &gt;&gt;
                </Button>
            </Box>
        </Box>
    );
};

export default URLUpload;
