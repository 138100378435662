import React from 'react';
import Auth from '../components/AuthPage/Auth';
import AnimationWrapper from '../components/common/AnimationWrapper';

const AuthContainer: React.FC = () => (
    <AnimationWrapper>
        <Auth />
    </AnimationWrapper>
);

export default AuthContainer;
