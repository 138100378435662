import React from 'react';
import Chat from '../components/ChatPage/Chat';
import AnimationWrapper from '../components/common/AnimationWrapper';

const ChatContainer: React.FC = () => (
    <AnimationWrapper>
        <Chat />
    </AnimationWrapper>
);

export default ChatContainer;
