import axios from 'axios';

class UserService {
    private readonly baseUrl: string;

    constructor() {
        this.baseUrl = 'https://app3-g4lztzykma-uc.a.run.app/users';
        // this.baseUrl = 'http://localhost:3001/users';
    }

    async createUser(data: any) {
        try {
            const response = await axios.post(`${this.baseUrl}/create`, data);
            return response.data;
        } catch (error) {
            console.error('Error creating user:', error);
            throw error;
        }
    }
}

export default new UserService();
