import axios from 'axios';

class JunoService {
    private readonly baseUrl: string;

    constructor() {
        this.baseUrl = 'https://app3-g4lztzykma-uc.a.run.app/juno';
        // this.baseUrl = 'http://localhost:3001/juno';
    }

    async createJuno(data: any) {
        try {
            const response = await axios.post(this.baseUrl, data);
            return response.data;
        } catch (error) {
            console.error('Error creating Juno:', error);
            throw error;
        }
    }

    async updateScrapedDataUrl(id: number, scrapedDataUrl: string) {
        try {
            const response = await axios.put(`${this.baseUrl}/${id}/scrapedDataUrl`, { scrapedDataUrl });
            return response.data;
        } catch (error) {
            console.error('Error updating scraped data URL:', error);
            throw error;
        }
    }

    async updateCustomUrl(id: number, customUrl: string) {
        try {
            const response = await axios.put(`${this.baseUrl}/${id}/customUrl`, { customUrl });
            return response.data;
        } catch (error) {
            console.error('Error updating custom URL:', error);
            throw error;
        }
    }

    async updateJunoData(id: number, sliderValues: number[], prompt: string) {
        try {
            const response = await axios.put(`${this.baseUrl}/${id}`, { sliderValues, prompt });
            return response.data;
        } catch (error) {
            console.error('Error updating Juno data:', error);
            throw error;
        }
    }

    async findOne(id: number) {
        try {
            const response = await axios.get(`${this.baseUrl}/${id}`);
            return response.data;
        } catch (error) {
            console.error('Error fetching Juno data:', error);
            throw error;
        }
    }

    async saveImageToS3(id: number) {
        try {
            const response = await axios.put(`${this.baseUrl}/${id}/saveToS3`);
            return response.data;
        } catch (error) {
            console.error('Error saving image to S3:', error);
            throw error;
        }
    }
}

export default new JunoService();
