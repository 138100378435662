import React, { useState } from 'react';
import { Button, Typography, Box, useMediaQuery } from '@mui/material';
import SliderComponent from './Slider';
import SlidersControls from './SlidersControls';
import { useNavigate } from 'react-router-dom';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import JunoService from '../services/JunoService';
import { styled } from '@mui/system';

const theme = createTheme({
    components: {
        MuiCssBaseline: {
            styleOverrides: {
                body: {
                    overflowX: 'hidden',
                },
            },
        },
    },
});

const CenteredBox = styled(Box)(({ theme }) => ({
    display: 'flex',
    justifyContent: 'center',
    width: '100%',
    [theme.breakpoints.up('md')]: {
        justifyContent: 'center',
    },
}));

const MainPage: React.FC = () => {
    const navigate = useNavigate();
    const [activeImage, setActiveImage] = useState<number>(0);

    const sliderValues = [
        { name: "Friendly & Chatty", values: [9, 9, 3] },
        { name: "Precise & Professional", values: [4, 3, 8] },
        { name: "Bold and Techy", values: [5, 6, 9] },
        { name: "Polite and Concise", values: [8, 4, 6] },
        { name: "Custom", values: [] }
    ];

    const images = [
        'https://juno-onboarding-demo.s3.eu-west-2.amazonaws.com/images/standart-images/1.png',
        'https://juno-onboarding-demo.s3.eu-west-2.amazonaws.com/images/standart-images/2.png',
        'https://juno-onboarding-demo.s3.eu-west-2.amazonaws.com/images/standart-images/3.png',
        'https://juno-onboarding-demo.s3.eu-west-2.amazonaws.com/images/standart-images/4.png',
        '/images/5.png' // Custom image
    ];

    const handleButtonClick = async () => {
        if (localStorage.getItem('junoId')) {
            localStorage.removeItem('junoId');
        }

        if (activeImage === 4) {
            try {
                const response = await JunoService.createJuno({ name: "Custom", sliderValues: [], imageUrl: '', customUrl: '' });
                localStorage.setItem('junoId', response._id);
                navigate('/create-custom-image');
            } catch (error) {
                console.error('Failed to create custom Juno', error);
            }
        } else {
            const selectedTemplate = {
                name: sliderValues[activeImage].name,
                sliderValues: sliderValues[activeImage].values,
                imageUrl: images[activeImage],
                customUrl: images[activeImage]
            };
            try {
                const response = await JunoService.createJuno(selectedTemplate);
                localStorage.setItem('junoId', response._id);
                navigate('/upload-url');
            } catch (error) {
                console.error('Failed to create Juno', error);
            }
        }
    };

    const isMobile = useMediaQuery('(max-width: 700px)');

    return (
        <ThemeProvider theme={theme}>
            <CssBaseline />
            <Box
                display="flex"
                flexDirection="column"
                alignItems="center"
                textAlign="center"
                p={2}
                sx={{
                    fontFamily: "'Inter', sans-serif",
                    height: '100vh',
                    justifyContent: 'space-between',
                    overflow: isMobile ? 'auto' : 'hidden',
                    width: '100vw',
                    boxSizing: 'border-box'
                }}
            >
                <Box width="100%">
                    <Typography variant="h3" mb={1} sx={{ fontSize: '32px' }}>Choose your Juno</Typography>
                    <Typography variant="h6" mb={2} sx={{ fontSize: '20px' }}>
                        {sliderValues[activeImage]?.name || "Simple"}
                    </Typography>
                    <Box
                        width="100%"
                        mb={9}
                        display="flex"
                        justifyContent="center"
                    >
                        <SliderComponent activeImage={activeImage} setActiveImage={setActiveImage} />
                    </Box>
                    {activeImage === 5 ? (
                        <Typography variant="body1" mb={2} sx={{ fontSize: '20px' }}>
                            You can create your own Juno, just select the desired options.
                        </Typography>
                    ) : (
                        <CenteredBox>
                            <SlidersControls values={sliderValues[activeImage]?.values || []} />
                        </CenteredBox>
                    )}
                </Box>
                <Button
                    variant="contained"
                    onClick={handleButtonClick}
                    sx={{
                        width: '90%',
                        height: '7%',
                        backgroundColor: '#000',
                        fontSize: '16px',
                        borderRadius: 2,
                        textTransform: 'none',
                        mb: 4
                    }}
                >
                    {activeImage === 5 ? 'Create custom' : 'Next step >>'}
                </Button>
            </Box>
        </ThemeProvider>
    );
};

export default MainPage;
