import React from 'react';
import { motion } from 'framer-motion';

interface AnimationWrapperProps {
    children: React.ReactNode;
}

const AnimationWrapper: React.FC<AnimationWrapperProps> = ({ children }) => (
    <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
        transition={{ duration: 1 }}
    >
        {children}
    </motion.div>
);

export default AnimationWrapper;
