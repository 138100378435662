import React, { useState } from 'react';
import { TextField, Slider, Button, Box, Typography, styled } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import JunoService from '../services/JunoService';
import LoadingScreen from '../LoadingScreen/LoadingScreen';

const CreateCustomImage: React.FC = () => {
    const navigate = useNavigate();
    const [description, setDescription] = useState<string>('');
    const [innovate, setInnovate] = useState<number>(0);
    const [modern, setModern] = useState<number>(0);
    const [friendly, setFriendly] = useState<number>(0);
    // const [playful, setPlayful] = useState<number>(0);
    const [loading, setLoading] = useState<boolean>(false);

    const CustomStyledSlider = styled(Slider)(({ theme }) => ({
        '& .MuiSlider-thumb': {
            color: '#7F7DDA',
            transition: 'transform 0.2s ease',
        },
        '& .MuiSlider-track': {
            transition: 'width 0.2s ease',
        },
        '& .MuiSlider-rail': {
            transition: 'width 0.2s ease',
        }
    }));

    const handleSaveData = async () => {
        const id = parseInt(localStorage.getItem('junoId') || '0', 10);
        if (!id) {
            console.error('Invalid ID');
            return;
        }

        const sliderValues = [innovate, modern, friendly]; // , playful
        setLoading(true); // Покажите экран загрузки
        try {
            await JunoService.updateJunoData(id, sliderValues, description);
            navigate('/final-custom-image');
        } catch (error) {
            console.error('Failed to update Juno data', error);
        } finally {
            setLoading(false); // Спрячьте экран загрузки
        }
    };

    return (
        <Box
            display="flex"
            flexDirection="column"
            alignItems="center"
            textAlign="center"
            p={2}
            sx={{
                fontFamily: "'Inter', sans-serif",
                height: '100vh',
                justifyContent: 'space-between',
                overflow: 'hidden',
            }}
        >
            {loading && <LoadingScreen />} {/* Покажите экран загрузки, если loading === true */}
            <Typography variant="h3" sx={{ fontSize: '32px', fontFamily: "'Inter', sans-serif" }}>
                Create your Juno
            </Typography>
            <Box
                display="flex"
                flexDirection="column"
                alignItems="center"
                width="100%"
                height="100%"
                maxWidth="600px"
                sx={{ fontFamily: "'Inter', sans-serif", mt: 2 }}
            >
                <TextField
                    label="Describe what your Juno should look like."
                    variant="outlined"
                    fullWidth
                    multiline
                    rows={8}
                    sx={{ mb: 3 }}
                    value={description}
                    onChange={(e) => setDescription(e.target.value)}
                />
                <Box width="100%" display="flex" justifyContent="center" alignItems="center" mb={1}>
                    <Box width="80px" textAlign="left">
                        <Typography sx={{ fontSize: '14px' }}>Professional</Typography>
                    </Box>
                    <CustomStyledSlider
                        value={innovate}
                        onChange={(e, val) => setInnovate(val as number)}
                        step={1}
                        min={0}
                        max={10}
                        sx={{ flex: 1, mx: 2 }}
                    />
                    <Box width="80px" textAlign="right">
                        <Typography sx={{ fontSize: '14px' }}>Friendly</Typography>
                    </Box>
                </Box>
                <Box width="100%" display="flex" justifyContent="center" alignItems="center" mb={1}>
                    <Box width="80px" textAlign="left">
                        <Typography sx={{ fontSize: '14px' }}>Brief</Typography>
                    </Box>
                    <CustomStyledSlider
                        value={modern}
                        onChange={(e, val) => setModern(val as number)}
                        step={1}
                        min={0}
                        max={10}
                        sx={{ flex: 1, mx: 2 }}
                    />
                    <Box width="80px" textAlign="right">
                        <Typography sx={{ fontSize: '14px' }}>Chatty</Typography>
                    </Box>
                </Box>
                <Box width="100%" display="flex" justifyContent="center" alignItems="center" mb={1}>
                    <Box width="80px" textAlign="left">
                        <Typography sx={{ fontSize: '14px' }}>Simple</Typography>
                    </Box>
                    <CustomStyledSlider
                        value={friendly}
                        onChange={(e, val) => setFriendly(val as number)}
                        step={1}
                        min={0}
                        max={10}
                        sx={{ flex: 1, mx: 2 }}
                    />
                    <Box width="80px" textAlign="right">
                        <Typography sx={{ fontSize: '14px' }}>Technical</Typography>
                    </Box>
                </Box>
                {/*<Box width="100%" display="flex" justifyContent="center" alignItems="center" mb={1}>*/}
                {/*    <Box width="80px" textAlign="left">*/}
                {/*        <Typography sx={{ fontSize: '14px' }}>Playful</Typography>*/}
                {/*    </Box>*/}
                {/*    <CustomStyledSlider*/}
                {/*        value={playful}*/}
                {/*        onChange={(e, val) => setPlayful(val as number)}*/}
                {/*        step={1}*/}
                {/*        min={0}*/}
                {/*        max={10}*/}
                {/*        sx={{ flex: 1, mx: 2 }}*/}
                {/*    />*/}
                {/*    <Box width="80px" textAlign="right">*/}
                {/*        <Typography sx={{ fontSize: '14px' }}>Serious</Typography>*/}
                {/*    </Box>*/}
                {/*</Box>*/}
            </Box>
            <Button
                sx={{
                    width: '100%',
                    height: '60px',
                    backgroundColor: '#000000',
                    fontSize: '16px',
                    borderRadius: '8px',
                    color: '#ffffff',
                    textTransform: 'none',
                    mb: 1,
                    '&:hover': {
                        backgroundColor: '#3f51b5',
                    },
                    '&:active': {
                        backgroundColor: '#303f9f',
                    },
                }}
                onClick={handleSaveData}
            >
                Next Step &gt;&gt;
            </Button>
        </Box>
    );
};

export default CreateCustomImage;
