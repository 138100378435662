import React from 'react';
import { Box, CircularProgress, Typography } from '@mui/material';

const LoadingScreen: React.FC = () => {
    return (
        <Box
            sx={{
                position: 'fixed',
                top: 0,
                left: 0,
                width: '100%',
                height: '100%',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                backgroundColor: 'rgba(255, 255, 255, 0.5)',
                zIndex: 9999,
                animation: 'fadeIn 0.5s ease-in-out forwards',
                '@keyframes fadeIn': {
                    from: { backgroundColor: 'rgba(0, 0, 0, 0.5)' },
                    to: { backgroundColor: 'rgba(255, 255, 255, 1)' },
                }
            }}
        >
            <CircularProgress sx={{ color: '#000000', mb: 2 }} />
            <Typography variant="h6" sx={{ color: '#000000' }}>
                Creating Juno
            </Typography>
        </Box>
    );
};

export default LoadingScreen;
