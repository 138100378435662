import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import MainPageContainer from './pages/MainPageContainer';
import CreateCustomImageContainer from './pages/CreateCustomImageContainer';
import FinalCustomImageContainer from './pages/FinalCustomImageContainer';
import URLUploadContainer from './pages/URLUploadContainer';
import AuthContainer from './pages/AuthContainer';
import ChatContainer from './pages/ChatContainer';
import AuthCallback from "./components/AuthPage/AuthCallback";

const App: React.FC = () => (
    <Router>
        <Routes>
            <Route path="/" element={<MainPageContainer />} />
            <Route path="/create-custom-image" element={<CreateCustomImageContainer />} />
            <Route path="/final-custom-image" element={<FinalCustomImageContainer />} />
            <Route path="/upload-url" element={<URLUploadContainer />} />
            <Route path="/auth" element={<AuthContainer />} />
            <Route path="/auth/callback" element={<AuthCallback />} />
            <Route path="/chat" element={<ChatContainer />} />
        </Routes>
    </Router>
);

export default App;
