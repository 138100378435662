import React, { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import UserService from '../services/UserService';
import LoadingScreen from '../LoadingScreen/LoadingScreen'; // Импортируем компонент с анимацией

const AuthCallback: React.FC = () => {
    const location = useLocation();
    const navigate = useNavigate();

    useEffect(() => {
        const query = new URLSearchParams(location.search);
        const userString = query.get('user') || '{}';

        // Логируем данные, которые мы получили из URL
        console.log('Received user string:', userString);

        const user = JSON.parse(decodeURIComponent(userString));

        // Логируем распарсенные данные пользователя
        console.log('Parsed user object:', user);

        const _id = parseInt(localStorage.getItem('junoId') || '0', 10);
        user._id = _id;

        // Логируем данные пользователя перед отправкой на сервер
        console.log('User object with _id:', user);

        UserService.createUser(user)
            .then(() => {
                console.log('User successfully created');
                navigate('/chat');
            })
            .catch(error => {
                console.error('Error creating user:', error);

                // Проверка на ошибку 400
                if (error.response && error.response.status === 400) {
                    navigate('/chat');
                } else {
                    // Можно также добавить обработку других типов ошибок
                    console.error('Unhandled error:', error);
                }
            });
    }, [location, navigate]);

    return <LoadingScreen />; // Показываем анимацию загрузки
};

export default AuthCallback;
